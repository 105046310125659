
/*--------------------------------------------------------------
#0.1    Theme Reset Style
--------------------------------------------------------------*/
@import url('https://fonts.googleapis.com/css2?family=Teko:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@400;500;600;700;800;900&display=swap');

body {
	font-family: 'Rubik', sans-serif;
	font-weight: 400;
	font-style: normal;
	font-size: 16px;
	color: #777;
}

.body_wrap {
	overflow: hidden;
	position: relative;
}

.img {
	max-width: 100%;
	transition: all 0.3s ease-out 0s;
	-webkit-transition: all 0.3s ease-out 0s;
	-moz-transition: all 0.3s ease-out 0s;
	-ms-transition: all 0.3s ease-out 0s;
	-o-transition: all 0.3s ease-out 0s;
}

a,
.button {
	-webkit-transition: all 0.3s ease-out 0s;
	transition: all 0.3s ease-out 0s;
}

a:focus,
.button:focus {
	text-decoration: none;
	outline: none;
}

a {
	text-decoration: none;
}

a:focus,
a:hover,
.gallery-cat a:hover,
.footer-menu li a:hover {
	text-decoration: none;
}

a,
button {
	color: #000;
	outline: medium none;
	cursor: pointer;
}

a:hover {
	color: #B78D65;
}

button {
	padding: 0px;
	border: none;
	outline: none;
	background: none;
	display: inline-block;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: 'Teko', sans-serif;
	font-weight: normal;
	color: #000000;
	margin: 0;
	font-style: normal;
	font-weight: 600;
	letter-spacing: 1px
}

ul {
	margin: 0px;
	padding: 0px;
}

li {
	list-style: none
}

img {
	max-width: 100%;
	height: auto;
}

p {
	font-size: 18px;
	font-weight: normal;
	line-height: 30px;
	color: #777;
	margin-bottom: 15px;
}

p:last-child {
	margin: 0
}

label {
	color: #7e7e7e;
	cursor: pointer;
	font-size: 14px;
	font-weight: 400;
}

input,
textarea,
select {
	width: 100%;
	height: 55px;
	background-color: #f8f8f8;
	border: none;
	padding: 0px 20px;
	outline: none
}


*::-moz-selection {
	background: #c6a280;
	color: #fff;
	text-shadow: none;
}

::-moz-selection {
	background: #c6a280;
	color: #fff;
	text-shadow: none;
}

::selection {
	background: #c6a280;
	color: #fff;
	text-shadow: none;
}

*::-moz-placeholder {
	color: #999;
	font-size: 16px;
	opacity: 1;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

*::-webkit-input-placeholder {
	color: #999;
	font-size: 16px;
	opacity: 1;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

*:-ms-input-placeholder {
	color: #999;
	font-size: 16px;
	opacity: 1;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

*::placeholder {
	color: #999;
	font-size: 16px;
	opacity: 1;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}
/*--------------------------------------------------------------
#0.2    Global Elements
--------------------------------------------------------------*/

section {
	padding: 100px 0
}

.cta-btn {
	display: inline-block;
	padding: 15px 35px;
	font-weight: 500;
	text-transform: capitalize;
	border: 1px solid transparent
}

.btn-fill {
	background: #B78D65 none repeat scroll 0 0;
	color: #fff;
	padding: 17px 35px;
	border-color: #B78D65
}

.btn-fill:hover {
	background: transparent;
	color: #B78D65
}

.btn-border {
	border-color: #000;
	color: #000
}

.site-heading {
	margin-bottom: 30px
}

.sub-title {
	color: #B78D65;
	letter-spacing: 5px;
	font-weight: 500;
	margin-bottom: 10px;
	font-size: 24px;
}

.section-title {
	color: #000;
	font-size: 60px;
	letter-spacing: 2px;
	font-weight: 500;
	line-height: 70px;
}

.form-button {
	display: inline-block;
	background: #B78D65;
	border: 1px solid #B78D65;
	padding: 17px 35px;
	color: #fff;
	-webkit-transition: all 0.4s ease;
	transition: all 0.4s ease
}

.form-button:hover {
	background: transparent;
    color: #B78D65
}
/*Responsive*/
@media(max-width: 767px) {
	.section-title {
		font-size: 38px;
		line-height: 44px;
	}
	.sub-title {
		letter-spacing: 3px;
		font-size: 20px;
	}
	p {
		font-size: 16px;
		line-height: 26px;
	}
}
@media (min-width: 768px) and (max-width: 991px){
	.section-title {
		font-size: 45px;
		line-height: 54px;
	}
	p {
		font-size: 16px;
		line-height: 26px;
	}
	.counter-box p {
		letter-spacing: 1px;
	}
}
@media (width: 1024px){
	.section-title {
		font-size: 50px;
		letter-spacing: 2px;
		line-height: 55px;
	}
}